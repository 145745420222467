import _colorConvert from "color-convert";
var exports = {};
var convert = _colorConvert;

exports = function (cstr) {
  var m, conv, parts, alpha;

  if (m = /^((?:rgb|hs[lv]|cmyk|xyz|lab)a?)\s*\(([^\)]*)\)/.exec(cstr)) {
    var name = m[1];
    var base = name.replace(/a$/, "");
    var size = base === "cmyk" ? 4 : 3;
    conv = convert[base];
    parts = m[2].replace(/^\s+|\s+$/g, "").split(/\s*,\s*/).map(function (x, i) {
      if (/%$/.test(x) && i === size) {
        return parseFloat(x) / 100;
      } else if (/%$/.test(x)) {
        return parseFloat(x);
      }

      return parseFloat(x);
    });
    if (name === base) parts.push(1);
    alpha = parts[size] === undefined ? 1 : parts[size];
    parts = parts.slice(0, size);

    conv[base] = function () {
      return parts;
    };
  } else if (/^#[A-Fa-f0-9]+$/.test(cstr)) {
    var base = cstr.replace(/^#/, "");
    var size = base.length;
    conv = convert.rgb;
    parts = base.split(size === 3 ? /(.)/ : /(..)/);
    parts = parts.filter(Boolean).map(function (x) {
      if (size === 3) {
        return parseInt(x + x, 16);
      } else {
        return parseInt(x, 16);
      }
    });
    alpha = 1;

    conv.rgb = function () {
      return parts;
    };

    if (!parts[0]) parts[0] = 0;
    if (!parts[1]) parts[1] = 0;
    if (!parts[2]) parts[2] = 0;
  } else {
    conv = convert.keyword;

    conv.keyword = function () {
      return cstr;
    };

    parts = cstr;
    alpha = 1;
  }

  var res = {
    rgb: undefined,
    hsl: undefined,
    hsv: undefined,
    cmyk: undefined,
    keyword: undefined,
    hex: undefined
  };

  try {
    res.rgb = conv.rgb(parts);
  } catch (e) {}

  try {
    res.hsl = conv.hsl(parts);
  } catch (e) {}

  try {
    res.hsv = conv.hsv(parts);
  } catch (e) {}

  try {
    res.cmyk = conv.cmyk(parts);
  } catch (e) {}

  try {
    res.keyword = conv.keyword(parts);
  } catch (e) {}

  if (res.rgb) res.hex = "#" + res.rgb.map(function (x) {
    var s = x.toString(16);
    if (s.length === 1) return "0" + s;
    return s;
  }).join("");
  if (res.rgb) res.rgba = res.rgb.concat(alpha);
  if (res.hsl) res.hsla = res.hsl.concat(alpha);
  if (res.hsv) res.hsva = res.hsv.concat(alpha);
  if (res.cmyk) res.cmyka = res.cmyk.concat(alpha);
  return res;
};

export default exports;